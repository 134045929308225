export const ELECTRIC_RAILWAY_DISTRIBUTIONS = 'electric-railway-distributions'
export const ELECTRIC_RAILWAY_STATIONS = 'electric-railway-stations'
export const TRACTION_STATIONS = 'traction-stations'
export const TRACTION_LINES = 'traction-lines'
export const ELECTRICAL_DEVICES_FROM_TRACTION_LINES = 'electrical-devices-from-traction-lines'
export const ELECTRICAL_RAILWAY_VEHICLE_DEVICES = 'electrical-railway-vehicle-devices'
export const HIGH_CURRENT_DEVICES = 'high-current-devices'
export const CABLE_CAR_ELECTRICAL_DEVICES = 'cable-car-electrical-devices'
export const MOBILE_PLATFORMS_ON_ROAD = 'mobile-platforms-on-road'
export const TEST_ROOMS = 'test-rooms'
export const SECURITY_DEVICES = 'security-devices'
export const PROTECTION_AGAINST_ATMOSPHERIC_AND_STATIC_ELECTRICITY = 'protection-against-atmospheric-and-static-electricity'

export const ALL_ELECTRONIC_DEVICE_TYPES = [
  ELECTRIC_RAILWAY_DISTRIBUTIONS,
  ELECTRIC_RAILWAY_STATIONS,
  TRACTION_STATIONS,
  TRACTION_LINES,
  ELECTRICAL_DEVICES_FROM_TRACTION_LINES,
  ELECTRICAL_RAILWAY_VEHICLE_DEVICES,
  HIGH_CURRENT_DEVICES,
  CABLE_CAR_ELECTRICAL_DEVICES,
  MOBILE_PLATFORMS_ON_ROAD,
  TEST_ROOMS,
  SECURITY_DEVICES,
  PROTECTION_AGAINST_ATMOSPHERIC_AND_STATIC_ELECTRICITY,
] as const

export type ElectronicDeviceType = typeof ALL_ELECTRONIC_DEVICE_TYPES[number]
